<template>
    <DatePicker  v-model="getValue" @input="handleDatePicker" class="w-full" format="DD MMM, YYYY" value-type="YYYY-MM-DD" :clearable="false"  :key="getFieldId + getValue" :placehodler="getPlaceholderVal" />
</template>

<script>
import DatePicker from "vue2-datepicker";
// import { generateRandomString } from "@/utils/functions.js"
export default {
    components: {
        DatePicker,
    },
    props: {
        fieldData: {
            type: Object,
            require: true,
        },
    },
    data() {
        return {
            key: ''
        }
    },
    computed: {
        getValue: {
            get() {
                return this.fieldData.value || " ";
            },
            set(newValue) {
            //    let data = { value: newValue, field_id: this.fieldData.field_id };
            //     if (this.fieldData.multi_id) {
            //         data.multi_id = this.fieldData.multi_id;
            //         delete data.field_id
            //     }
            //     EventBus.$emit("changeFieldValue", data);
                this.$emit('input', newValue);
            },
        },
        getPlaceholderVal() {
            return this.fieldData?.placeholder || "Select Date";
        },
        getFieldId() {
            return this.fieldData.field_id || "";
        },
    },

    methods: {
        // generateRandomString,
        handleDatePicker(val) {
            // this.getValue = val;
            this.$forceUpdate();
            this.getValue = val
        },
    },
    mounted() {
        // this.key = this.generateRandomString()
    },
};
</script>
<style src="vue2-datepicker/index.css"></style>
<style lang="scss" scoped>
.w-full.mx-datepicker {
    width: 100% !important;
}
::v-deep {
    .mx-input {
        height: 40px !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        border-radius: 4px !important;
    }
}
</style>
